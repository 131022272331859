<script>
import Loader from "vue-element-loading";
import Vue from "vue";

import api from "@/api";
import { MyComponent } from "@/components/MyComponent.js";

export default {
  name: "lost",
  components: {
    Loader,
  },
  mixins: [MyComponent],
  data: function () {
    return {
      loading: false,
      username: "",
      email: "",
      phone: "",
      phone2: "",
      password: "",
      confirm_password: "",
    };
  },

  methods: {
    forgotPassword: function () {
      const data = {
        email: this.email,
      };

      this.loading = true;

      api.Util.axios
        .post("/forgotPassword/", data)
        .then((response) => {
          if (response.data.job != null) {
            Vue.$toast.success("Success. Check your email for the reset link.");

            this.$router.push({
              name: "Login",
            });
          } else {
            alert("Error creating account!");
          }
        })
        .catch((err) => {
          console.error(err.response);
          if (err.response.data) {
            alert(err.response.data);
          } else {
            alert("Error when performing action.");
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<template>
  <div id="lost">
    <div class="page-header">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-6">
            <Loader :active="loading" />
            <div id="sec" class="box border rounded">
              <h3 class="text-center">Forgot Password?</h3>

              <form role="form" @submit.prevent="forgotPassword">
                <div class="form-group">
                  <label for="exampleInputEmail1">Email address</label>
                  <input
                    name="email"
                    class="form-control"
                    id="exampleInputEmail1"
                    placeholder="x@y.com"
                    type="email"
                    v-model="email"
                    required
                  />
                </div>

                <!-- form-group// -->
                <div class="form-group mt-2">
                  <button type="submit" class="btn btn-primary btn-block">
                    Send Me A Reset Link
                  </button>
                </div>
                <!-- form-group// -->
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
#lost {
  background: rgb(246, 246, 246);
  padding-top: 20px;
  padding-bottom: 20px;

  h3 {
    margin-bottom: 15px;
  }

  .box {
    background: white;
    border: 1px solid #ccc;
    border: 1px solid rgb(241, 241, 241);
    box-shadow: rgb(227, 228, 231) 0px 2px 0px 0px;
    padding: 15px;
  }
  .form-links {
    a:hover {
      text-decoration: underline;
    }
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 10px;
    padding-left: 10px;
  }
  #log-btn {
    text-transform: uppercase;
    box-shadow: rgb(227, 228, 231) 0px 2px 0px 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
}
</style>
